import { Box, HStack, Spacer, Stack, Text, VStack, useToast } from '@chakra-ui/react'
import React from 'react'
import { MdAccountCircle, MdArrowForwardIos } from 'react-icons/md'
import {CiShoppingTag, CiStickyNote } from 'react-icons/ci'
import { IoHelpCircleOutline } from 'react-icons/io5'
import {IoIosLogOut} from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { UseAuthStore } from '../Hooks/Zustand/store'

function ProfilePage() {
  const height = window.innerHeight

  const navigate = useNavigate()
  const globalState = UseAuthStore();
  const toast = useToast()




  const tab = [
    {
      icon: <CiShoppingTag size={25} color="white"/>,
      title: 'Voucher Saya',
      route: '/voucher',
    },

    {
      icon: <CiShoppingTag size={25} color="white"/>,
      title: 'Orders',
      route: '/orders',
    },

    {
      icon: <CiStickyNote size={25} color="white"/>,
      title: 'Terms & Conditions',
      route: '/termcondition',
    },

    {
      icon: <IoHelpCircleOutline size={25} color="white"/>,
      title: 'Help',
      route: '/help',
    },

    {
      icon: <IoIosLogOut size={25} color="white"/>,
      title: 'Logout',
      route: 'logout'
    },

  ]

  const handleLogout = () => {
    localStorage.clear();
    globalState.setTokens({})
    globalState.setUser({})
    globalState.setIsLoggedIn(false)
    navigate('/')

    toast({
      title: 'DapatKomisi',
      description: 'Berhasil Logout',
      status: 'success',
      isClosable: true,
      duration: 9000,
      position: 'top'

    });
    window.location.reload()
  }


  return (
    <Box minH={height}>
      <Stack bgColor='gray.100' borderRadius={'xl'} shadow={'md'} alignItems='center' >
        <Stack alignItems="center" m={4} my={10} >
          <Stack bgColor='blue.600' shadow={'md'} alignItems={'center'} justifyContent='center' borderRadius={'xl'} p={5}>
            <MdAccountCircle size={120} color="white" />
            <Text color={'white'} fontWeight='bold' fontSize={'md'}>Rifqy Yusuf</Text>
          </Stack>
        </Stack>
      </Stack>

      <VStack bgColor='white' borderRadius={'xl'} shadow={'md'} alignItems='center'>
        {tab.map((x, index) => {
          return (
            <Stack w={'100%'} my={1} key={index} >
              <Stack onClick={x.route === 'logout' ? (() => handleLogout()) : (() => navigate(x.route)) }>
                <HStack space={2} alignItems='center' bgColor='white' p={'10px'} shadow={'md'} >
                  <Stack bgColor={'blue.600'} p={2} borderRadius='xl' shadow={'md'}>
                    { x.icon }
                  </Stack>
                  <Text letterSpacing={1} fontSize='sm'>{x.title}</Text>
                  <Spacer />
                  <MdArrowForwardIos size={20} color="blue.600" />
                </HStack>

              </Stack>
            </Stack>
          )
        })}
      </VStack>

    </Box>
  )
}

export default ProfilePage