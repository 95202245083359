import React from 'react';
import MissionsPage from '../Pages/Missions/MissionsPage';

const MissionsRouter = [
  {
    path: '/missions',
    element: (
      <MissionsPage />
    ),
  },
];

export default MissionsRouter;
