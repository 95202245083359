/* eslint-disable no-mixed-spaces-and-tabs */
import { Skeleton, Stack } from '@chakra-ui/react'
import React from 'react'
// import AuthContext from '../Routes/hooks/AuthContext'
import { CarouselFixComponent } from '../../Components/Gallery/CarouselFixComponent'
import dashboardArray from '../../Config/JSON/dashboard.json'
import FooterComponents from './Components/FooterComponents'
import HomeVoucherComponents from './Components/HomeVoucherComponents'



function HomePage() {

  const height = window.innerHeight
      
  return (
    <Stack bgColor={'gray.100'} minH={height} display={'flex'} flex={1} spacing={5} >
      {dashboardArray ? (
        <CarouselFixComponent images={dashboardArray} aspectRatio={1} />
      ) : (
        <Skeleton width={'100%'} h={height / 2} />
      )}

      <Stack>
        <HomeVoucherComponents/>
        {/* <HomeComponents /> */}
      </Stack>

      <Stack>
        <FooterComponents />
      </Stack>

    </Stack>
  )
}

export default HomePage