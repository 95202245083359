/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { Box, Heading, Stack, Text, useToast, Button } from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { UseAuthStore } from '../../../Hooks/Zustand/store';
import axiosInstanceBarier from '../../../Apis/axiosWithBarier';
import CardVoucherRow from '../../../Components/Card/CardVoucherRow';



function HomeVoucherComponents() {


  const [filterCategory, setFilterCategory] = useState('all');

  const [voucherList, setVoucherList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const toast = useToast()

  const globalState = UseAuthStore();





  const getDataVoucher = async (page) => {
    globalState.setIsLoading(true)
    try {
      const res = await axiosInstanceBarier.get(`/voucher/list?page=${currentPage}&limit=10`);
      if (page === 1) {
        setVoucherList(res?.data || []); // Set data keranjang belanja pada halaman pertama
      } else {
        setVoucherList((prevData) => [...prevData, ...(res?.data || [])]); // Tambahkan data baru ke data keranjang belanja sebelumnya
      }

      if (res?.data?.page >= res?.data?.total_pages) {
        setHasMore(false); // Tidak ada lagi produk untuk dimuat
      }

    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
    finally {
      globalState.setIsLoading(false)
    }
  }

  useEffect(() => {
    getDataVoucher()

    return () => {
      setVoucherList([])
      setCurrentPage(1);
      setHasMore(true);
    }
  }, [])


  const handleLoadMore = () => {
    if (hasMore) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      getDataVoucher(nextPage);
    }
  }

  const activeSlide = 0


  return (
    <Stack>
      <Box p={2}>


        <Heading size="md" mb={2}>
                    Vouchers
        </Heading>
        <Swiper slidesPerView={2} spaceBetween={10} initialSlide={activeSlide}>
          <SwiperSlide key={0}>
            <Box
              borderRadius="md"
              cursor="pointer"
              shadow="md"
              onClick={() => setFilterCategory('all')}
              align="center"
              p={2}
              borderBottomWidth={filterCategory === 'all' ? 2 : 0}
              borderColor={filterCategory === 'all' ? '#F05A28' : ''}
            >
              <Text
                fontWeight={
                  filterCategory === 'all' ? 'bold' : 'normal'
                }
              >
                                All
              </Text>
            </Box>
          </SwiperSlide>
        </Swiper>
      </Box>

      {voucherList?.length > 0 && (
        <Stack spacing={1}>
          {voucherList.map((x, i) => (
            <CardVoucherRow
              item={x}
              id={i}
              key={i}
            />
          ))}

          <Stack py={5} alignItems='center' justifyContent={'center'}>
            {
              hasMore ? (
                <Button
                  onClick={handleLoadMore}
                  bgColor='blue.500'
                  color='white'
                  size="sm"
                  _hover={{ bgColor: 'blue.600' }}
                  _active={{ bgColor: 'blue.700' }}
                >
                        Lihat lebih banyak
                </Button>
              ) : (
                <Text fontWeight='bold' fontSize='xs'>Semua produk telah dimuat.</Text>
              )
            }
          </Stack>
        </Stack>
      )}


    </Stack>
  )
}

export default HomeVoucherComponents