import { Divider, HStack, Heading, Image, Stack, Text, useToast } from '@chakra-ui/react'
import React, {useEffect, useState} from 'react'
import DynamicButton from '../../Components/Buttons/DynamicButton'
import { UseAuthStore } from '../../Hooks/Zustand/store';
import axiosInstanceBarier from '../../Apis/axiosWithBarier';
import CardVoucherRow from '../../Components/Card/CardVoucherRow';
import CardMyVoucherRow from '../../Components/Card/CardMyVoucherRow';

function VoucherPage() {


  const height = window.innerHeight

  const [voucherList, setVoucherList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const toast = useToast()

  const globalState = UseAuthStore();

  const getDataVoucher = async (page) => {
    globalState.setIsLoading(true)
    try {
      const res = await axiosInstanceBarier.get(`/voucher/my-voucher?page=${currentPage}&limit=10`);
      if (page === 1) {
        setVoucherList(res?.data || []); // Set data keranjang belanja pada halaman pertama
      } else {
        setVoucherList((prevData) => [...prevData, ...(res?.data || [])]); // Tambahkan data baru ke data keranjang belanja sebelumnya
      }

      if (res?.data?.page >= res?.data?.total_pages) {
        setHasMore(false); // Tidak ada lagi produk untuk dimuat
      }

    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
    finally {
      globalState.setIsLoading(false)
    }
  }

  useEffect(() => {
    getDataVoucher()

    return () => {
      setVoucherList([])
      setCurrentPage(1);
      setHasMore(true);
    }
  }, [])


  const handleLoadMore = () => {
    if (hasMore) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      getDataVoucher(nextPage);
    }
  }
  return (
    <Stack minH={height} p={2}>
      <Stack alignItems={'flex-end'}>
        <Heading size={'md'} >My Voucher</Heading>
      </Stack>
      <Divider />
      {voucherList?.length > 0 ? (
        voucherList?.map((x, index) =>
          <Stack bgColor={'white'} key={index} borderRadius='xl' p={1} >
            <CardMyVoucherRow
              item={x}
              id={index}
              key={index}
            />
          </Stack>
        )
      ) : (
        <Stack h={height / 2} alignItems={'center'} justifyContent='center' >
          <Text color={'gray.500'} fontWeight='bold'>Tidak ada data voucher</Text>
        </Stack>
      )}
    </Stack>
  )
}

export default VoucherPage