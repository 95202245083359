import React from 'react';
import ProfilePage from '../Pages/ProfilePage';
import TermConditionPage from '../Pages/TermConditionPage'

const ProfileRouter = [
  {
    path: '/profile',
    element: (
      <ProfilePage />
    ),
  },
  {
    path: '/termcondition',
    element: (
      <TermConditionPage />
    ),
  },
];

export default ProfileRouter;
