/* eslint-disable react/prop-types */
import { Box, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react'
import QRCode from 'react-qr-code';
import axiosInstanceBarier from '../../../Apis/axiosWithBarier';
import { Swiper, SwiperSlide } from 'swiper/react';
import CardProductVoucherCol from '../../../Components/Card/CardProductVoucherCol';


function MyVoucherDetail({ voucherActive }) {

  const [voucherDetail, setVoucherDetail] = useState('')
  const toast = useToast()

  const getDataDetail = async () => {
    try {
      const res = await axiosInstanceBarier.get(`/voucher/my-voucher-detail?voucherId=${voucherActive._id}`)
      setVoucherDetail(res?.data || '')
      console.log(res?.data || '')
    } catch (error) {
      toast({
        title: 'Gengacoan',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  useEffect(() => {
    getDataDetail()
    
    return () => {
      setVoucherDetail('')
    }
  }, [voucherActive._id])
    


  return (
    <Stack alignItems={'center'} justifyContent={'center'} pt={3}>
      <QRCode
        size={256}
        style={{ height: 'auto', maxWidth: '100px' }}
        value={voucherActive?.qrCode}
        viewBox={'0 0 256 256'}
      />
      <Text fontSize={'xs'} fontWeight={500} color={'gray.800'}>Used : {voucherDetail?.used}</Text>
      <Stack w={'full'} m={1}>
        <Text fontWeight={500}>Menu</Text>
        <Box >
          <Swiper slidesPerView={2} spaceBetween={10}>
            {voucherDetail?.voucher?.menus?.map((menu, i) => (
              <SwiperSlide key={i}>
                <CardProductVoucherCol
                  menu={menu}
                  id={i}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Stack>
    </Stack>
  )
}

export default MyVoucherDetail