/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Container } from '@chakra-ui/react';
import { Fragment } from 'react';
import AppHeader from '../Components/Headers/AppHeader';
import AppFooter from '../Components/Footers/AppFooter';

function Layout({ children }) {
  return (
    <Fragment>
      <Container
        shadow="2xl"x
        padding={0}
        w="full"
        alignItems={'flex-end'}
        justifyContent="space-between"
      >
        <AppHeader />
        {children}
        <AppFooter />
      </Container>
    </Fragment>
  );
}

export default Layout;
