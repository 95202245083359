import React from 'react';
import VoucherPage from '../Pages/Voucher/VoucherPage';

const VoucherRouter = [
  {
    path: '/voucher',
    element: (
      <VoucherPage />
    ),
  },
];

export default VoucherRouter;
