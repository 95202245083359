/* eslint-disable react/prop-types */
import { Card, CardBody, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react'
import { formatFrice } from '../../Utils/Helper';
import DynamicButton from '../Buttons/DynamicButton';

function CardVoucherRow({item, id}) {  
  return (
    <Card direction="row" overflow="hidden" key={id}>
      <Flex
        direction="column"
        w="30%"
        justify="center"
        align="center"
        p={2}
      >
        <Image
          shadow={'md'}
          borderRadius={'md'}
          src={item?.image || ''}
          alt={item?.name || ''}
          style={{ width: '100%', height: '120px', objectFit: 'cover' }}
        />
      </Flex>
      <CardBody w="70%">
        <Stack spacing={1}>
          <Heading size="sm" noOfLines={1}>{item?.name || ''}</Heading>
          <Text fontSize="xs">{item?.description || ''}</Text>

          <Text fontSize="sm">
                            Rp {formatFrice(parseInt(item?.price || 0))}
          </Text>
          <Stack alignItems={'flex-end'}>
            <Stack>
              {item?.used === 0 || item?.used === undefined ? (
                <DynamicButton
                  action={'delete'}
                  title={'Habis'}
                  size={'sm'}
                  variant={'solid'}
                  disabled={true}
                />
              ) : (
                <DynamicButton
                  action={'create'}
                  title={'Pesan'}
                  size={'sm'}
                  variant={'solid'}
                />
              )}

            </Stack>
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  )
}

export default CardVoucherRow