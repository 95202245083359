/* eslint-disable react/react-in-jsx-scope */
'use client'

import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  useBreakpointValue,
  Icon,
  useToast,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { useState } from 'react'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../../Apis/axiosWithNoBarier'
import { checkSignupData } from '../../Hooks/Middleware/UserMiddleWare'

const Blur = (props) => {
  return (
    <Icon
      width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  )
}

export default function JoinOurTeam() {

  const toast = useToast()
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const [loading, setLoading] = useState(false)

  const [dataAccount, setDataAccount] = useState({
    name: '',
    email: '',
    companyName: 'gacoan',
    phoneNumber: '',
    role: 2,
    password: '',
    confirmPassword: ''
  })

  const handleAddData = (e) => {
    const { id, value } = e.target;
    if (id === 'role' && value !== 'dropship') {
      const { ...restData } = dataAccount;
      setDataAccount({ ...restData, [id]: value });
    } else {
      setDataAccount({ ...dataAccount, [id]: value });
    }
  }

  const handleSubmit = async () => {


    const validationResult = checkSignupData(dataAccount);
    if (!validationResult.success) {
      toast(validationResult.error);
      return;
    }

    setLoading(true)
    try {
      const res = await axiosInstance.post('/auth/register', dataAccount)
      if (res) {
        toast({
          title: 'DapatKomisi',
          description: `Anda berhasil mendaftar sebagai ${dataAccount?.role === 2 ? 'user': 'admin'}. ${dataAccount.role === 1 ? 'Silahkan lanjutkan ke halaman admin' : 'Selamat berbelanja'}`,
          status: 'success',
          isClosable: true,
          duration: 9000,
          position: 'top'

        });

        if (dataAccount.role === 1) {
          window.open('https://admin.dapatkomisi.com', '_blank')
        }

        navigate('/login')

      }
    } catch (error) {
      toast({
        title: 'DapatKomisi',
        description: error.response.data.message,
        status: 'error',
        isClosable: true,
        duration: 9000,
        position: 'top'

      });

    }
    finally {
      setLoading(false)
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirmVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };



  return (
    <Stack position={'relative'} h='100vh' alignItems={'center'} justifyContent='center' bgColor='blue.600'>
      <Container
        as={SimpleGrid}
        maxW={'7xl'}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}>
        <Stack
          bg={'gray.100'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={5}
          maxW={{ lg: 'lg' }}>
          <Stack spacing={4}>
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
              Bergabung sekarang
              <Text as={'span'} bgGradient="linear(to-r, red.400,pink.400)" bgClip="text">
                !
              </Text>
            </Heading>
            <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
              Jadilah pelanggan Gen Gacoan untuk mempermudah transaksi cepat, tidak perlu antrian !
            </Text>
          </Stack>
          <Box as={'form'} mt={10}>
            <Stack spacing={2}>
              <Input
                placeholder="Nama"
                size='sm'
                shadow={'md'}
                bgColor={'white'}
                color={'black'}
                _placeholder={{
                  color: 'gray.500',
                }}
                id='name'
                onChange={(e) => handleAddData(e)}
              />
              <Input
                placeholder="Email"
                size='sm'
                shadow={'md'}
                bgColor={'white'}
                color={'black'}
                _placeholder={{
                  color: 'gray.500',
                }}
                id='email'
                onChange={(e) => handleAddData(e)}
              />
              <Input
                placeholder="Contoh: 0812346562616"
                size='sm'
                shadow={'md'}
                bgColor={'white'}
                color={'black'}
                _placeholder={{
                  color: 'gray.500',
                }}
                type='number'
                id='phoneNumber'
                onChange={(e) => handleAddData(e)}
              />
              <InputGroup
                w={{
                  base: '100%',
                  md: '100%',
                }}
                size='sm'
              >
                  
                <Input
                  w={{
                    base: '100%',
                    md: '100%',
                  }}
                  placeholder="password"
                  shadow={'md'}
                  type={showPassword ? 'text' : 'password'}
                  bgColor={'white'}
                  color={'black'}
                  id='password'
                  onChange={(e) => handleAddData(e)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                />
                <InputRightElement>
                  {showPassword ? (
                    <MdVisibilityOff
                      size={15}
                      color="black"
                      onClick={togglePasswordVisibility}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <MdVisibility
                      size={15}
                      color="black"
                      onClick={togglePasswordVisibility}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </InputRightElement>
              </InputGroup>

              <InputGroup
                w={{
                  base: '100%',
                  md: '100%',
                }}
                size='sm'
              >
                  
                <Input
                  w={{
                    base: '100%',
                    md: '100%',
                  }}
                  placeholder="Konfirmasi password"
                  shadow={'md'}
                  bgColor={'white'}
                  color={'black'}
                  type={showConfirmPassword ? 'text' : 'password'}
                  id='confirmPassword'
                  onChange={(e) => handleAddData(e)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                />
                <InputRightElement>
                  {showConfirmPassword ? (
                    <MdVisibilityOff
                      size={15}
                      color="black"
                      onClick={togglePasswordConfirmVisibility}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <MdVisibility
                      size={15}
                      color="black"
                      onClick={togglePasswordConfirmVisibility}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </InputRightElement>
              </InputGroup>

            </Stack>
            <Button
              fontFamily={'heading'}
              mt={8}
              size='sm'
              isLoading={loading}
              w={'full'}
              bgGradient="linear(to-r, red.400,pink.400)"
              color={'white'}
              onClick={() => handleSubmit()}
              _hover={{
                bgGradient: 'linear(to-r, red.400,pink.400)',
                boxShadow: 'xl',
              }}>
              Daftar
            </Button>
          </Box>
        </Stack>
      </Container>
      <Blur position={'absolute'} top={-10} left={-10} style={{ filter: 'blur(70px)' }} />
    </Stack>
  )
}