import { Box, Center, Heading, Icon, SimpleGrid, Spacer, Stack, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { IoCartOutline, IoHeartOutline, IoHomeOutline, IoPersonOutline } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import { IoTicketOutline } from 'react-icons/io5';
import { formatFrice } from '../../Utils/Helper';
import DynamicButton from '../Buttons/DynamicButton';


function AppFooter() {

  const [activeTab, setActiveTab] = useState('')
  const navigate = useNavigate()

  const totalVoucher = {
    voucher: 5,
  }



  return (
    <Box  zIndex={100} shadow='lg' position='sticky' bottom={0} >

      <Stack
        direction={['row']}
        w="full"
        color="white"
        bgColor={'blue.300'}
        p="3"
        zIndex={10}
        borderTopRadius='lg'
        alignItems={'center'}
      >
        <Box>
          <Text fontSize={'sm'}>Voucher saya :</Text>
          <Stack direction={['row']}>
            <Heading size={'sm'} >
              {((totalVoucher?.voucher || 0))} item
            </Heading>
          </Stack>
        </Box>
        <Spacer />
        <DynamicButton size={'sm'} variant={'solid'} color={'blue'} action={'custom'} title={'Voucher Saya'} onClick={() => navigate('/voucher')}/>
      </Stack>

      <Stack bgColor='blue.600' p={2}>
        <SimpleGrid columns={3} textAlign='center' fontSize='sm'>
          <Box>
            <Link to='/' onClick={() => setActiveTab('home')}>
              <Icon as={IoHomeOutline} boxSize='5' color={activeTab === 'home' ? ('blue.900') : ('white')} />
              <Text fontSize={'xs'} fontWeight='bold' color={activeTab === 'home' ? ('blue.900') : ('white')} >Home</Text>
            </Link>
          </Box>
          {/* <Box>
            <Link to='/missions' onClick={() => setActiveTab('missions')}>
              <Icon as={IoHeartOutline} boxSize='5' color={activeTab === 'missions' ? ('blue.900') : ('white')} />
              <Text fontSize={'xs'} fontWeight='bold' color={activeTab === 'missions' ? ('blue.900') : ('white')} >Missions</Text>
            </Link>
          </Box> */}
          <Center>
            <Link to='/voucher' onClick={() => setActiveTab('voucher')}>
              <Stack width='16' alignItems={'center'} justifyContent='center' spacing={0} color='white' height='16' p='2' borderRadius='full' mt='-5' bgColor='black' shadow='2xl'>
                <IoTicketOutline fontSize={'30px'} />

              </Stack>
            </Link>
          </Center>
          {/* <Box>
            <Link to='/orders' onClick={() => setActiveTab('orders')}>
              <Icon as={IoCartOutline} boxSize='5' color={activeTab === 'orders' ? ('blue.900') : ('white')} />
              <Text fontSize={'xs'} fontWeight='bold' color={activeTab === 'orders' ? ('blue.900') : ('white')} >Orders</Text>
            </Link>
          </Box> */}
          <Box>
            <Link to='/profile' onClick={() => setActiveTab('profile')}>

              <Icon as={IoPersonOutline} boxSize='5' color={activeTab === 'profile' ? ('blue.900') : ('white')} />
              <Text fontSize={'xs'} fontWeight='bold' color={activeTab === 'profile' ? ('blue.900') : ('white')} >Profile</Text>
            </Link>
          </Box>
        </SimpleGrid>
      </Stack>
    </Box>
  )
}

export default AppFooter