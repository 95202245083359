/* eslint-disable react/react-in-jsx-scope */
import { Stack } from '@chakra-ui/react';
import Layout from './Layouts';


import { UseAuthStore } from './Hooks/Zustand/store';
import { useLocation } from 'react-router-dom';
import AuthRouter from './Routes/AuthRouter';
import Preloader from './Components/Loading/Preloader';
import MainRouter from './Routes/MainRouter';
import { useEffect } from 'react';


function App() {
  const location = useLocation();

  const globalState = UseAuthStore();

  const handleRefresh = () => {

    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const tokens = localStorage.getItem('tokens');
    const user = localStorage.getItem('user');

    const newToken = JSON.parse(tokens);
    const newUser = JSON.parse(user);
    const newIsLoggedIn = JSON.parse(isLoggedIn);

    globalState.setTokens(newToken);
    globalState.setUser(newUser);
    globalState.setIsLoggedIn(newIsLoggedIn);
  };

  useEffect(() => {
    handleRefresh();

    return () => {};
  }, []);



  return (
    <Stack>
      <Preloader />

      {location.pathname !== '/login' &&
      location.pathname !== '/signup' &&
      location.pathname !== '/register' &&
      location.pathname !== '/reset-password'
        ? (
          <Layout>
            <MainRouter />
          </Layout>
        ) : (
          <AuthRouter />
        )}
    </Stack>
  );
}

export default App;
