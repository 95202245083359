/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { GalleryFix } from './GalleryFix'

export const CarouselFixComponent = (props) => {
  let images = []
  if (!props.images)
    images = [

      {
        id: '01',
        image: 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_mobile%2FScreen%20Shot%202024-02-07%20at%2020.52.06.png?alt=media&token=4fa3bc2f-d636-475e-879e-dc75f2c0e5a6',
        alt: ' ',
      },
      {
        id: '02',
        image: 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_mobile%2FScreen%20Shot%202024-02-07%20at%2020.52.11.png?alt=media&token=f04f9260-7161-4385-96d5-313dd06a91d4',
        alt: ' ',
      },
      {
        id: '03',
        image: 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_mobile%2FScreen%20Shot%202024-02-07%20at%2020.52.18.png?alt=media&token=86405a98-f6e1-4de8-9e91-02c303a9b827',
        alt: ' ',
      },
      {
        id: '04',
        image: 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_mobile%2FScreen%20Shot%202024-02-07%20at%2020.52.26.png?alt=media&token=2d30bbac-a9a2-421c-a8ec-34384f813d19',
        alt: ' ',
      },
      {
        id: '05',
        image: 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_mobile%2FScreen%20Shot%202024-02-07%20at%2020.52.44.png?alt=media&token=5de27430-7045-490a-8286-c864c1835942',
        alt: ' ',
      }
    ]
  else
    images = props.images

  return (
    <GalleryFix images={images} aspectRatio={props.aspectRatio} />
  )
}