import React from 'react';
import ForgotPasswordPage from '../Pages/Authentication/ForgotPasswordPage';
import LoginPage from '../Pages/Authentication/LoginPage';
import SigunUpPage from '../Pages/Authentication/SignUpPage';

const AuthenticationRouter = [
  {
    path: '/',
    element: <LoginPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/signup',
    element: <SigunUpPage />,
  },
  {
    path: '/reset-password',
    element: <ForgotPasswordPage />,
  },

];

export default AuthenticationRouter;
