import React from 'react';
import OrderPage from '../Pages/Orders/OrderPage';

const OrdersRouter = [
  {
    path: '/orders',
    element: (
      <OrderPage />
    ),
  },
];

export default OrdersRouter;
