import { HStack, Heading, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { FaInstagram, FaTiktok, FaWhatsapp } from 'react-icons/fa'
import colors from '../../../Utils/colors'

function FooterComponents() {
  return (
    <Stack bgColor={colors.theme} p={5} spacing={3}>

      <HStack alignItems={'center'} justifyContent='center' spacing={8}>
        <a href={'https://www.instagram.com/'} target="_blank" rel="noopener noreferrer">
          <Stack bgColor={'white'} borderRadius='full' p={3} shadow={'base'}>
            <FaInstagram size={15} color={colors.theme} />
          </Stack>
        </a>

        <a href={'https://www.tiktok.com/'} target="_blank" rel="noopener noreferrer">
          <Stack bgColor={'white'} borderRadius='full' p={3} shadow={'base'}>
            <FaTiktok size={15} color={colors.theme} />
          </Stack>
        </a>

        <a href={'https://api.whatsapp.com/send?phone=087887147471&text=Halo, admin dapatkomisi :)'} target="_blank" rel="noopener noreferrer">
          <Stack bgColor={'white'} borderRadius='full' p={3} shadow={'base'}>
            <FaWhatsapp size={15} color={colors.theme} />
          </Stack>
        </a >
      </HStack>

      <Stack>
        <Heading fontSize={'md'} color='white' textAlign='center' textTransform={'uppercase'}>Mie Gacoan</Heading>
        <Text fontSize={'xs'} color='white' textAlign='center'>Jl. S. Supriadi No.74, Sukun, Kec. Sukun, Kota Malang, Jawa Timur 65148, Indonesia</Text>
      </Stack>


    </Stack>
  )
}

export default FooterComponents