import { HStack, Spinner } from '@chakra-ui/react';
import React from 'react';
import { UseAuthStore } from '../../Hooks/Zustand/store';

export default function Preloader() {
  const screenHeight = window.innerHeight;

  const globalState = UseAuthStore();



  return (
    globalState.isLoading && (
      <HStack
        space={2}
        justifyContent="center"
        position="fixed"
        zIndex={1000000}
        height={screenHeight}
        width="100%"
        bg="rgba(0, 0, 0, 0.7)"
      >
        {/* <Image src={gif} width='100px'/> */}
        <Spinner accessibilityLabel="Loading..." color="white" />
      </HStack>
    ) 
  );
}
