import { Heading, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import DynamicButton from '../Components/Buttons/DynamicButton';
import { UseAuthStore } from '../Hooks/Zustand/store';
import { useNavigate } from 'react-router-dom'



function TermConditionPage() {

  const height = window.innerHeight

  const globalState = UseAuthStore();

  const navigate = useNavigate()

  const handleNext = () => {
    globalState.setCondition(true)
    navigate('/')
  }


  return (
    <Stack m={5} minH={height / 1.3} alignItems={'center'} justifyContent={'center'}>


      <Heading fontSize={'md'}>
                Syarat dan Ketentuan Gen Gacoan
      </Heading>
      <Text>
                Aplikasi Gen Gacoan disediakan buat kenyamanan pelanggan setia Mie Gacoan dalam hal bertransaksi.
                Ada 3 keuntungan buat pelanggan :
      </Text>

      <Heading fontSize={'sm'}>
                A. Membayar iuran bulanan dan memperoleh mie Pedas manapun pada vkunjungan ke enam. Sedangkan pelanggan tinggal membayar makan dan minuman saja.
      </Heading>

      <Heading fontSize={'sm'}>
                B. Tersedian dompet digital untuk membayar secara virtual bilamana antrain di Kasir mengular
      </Heading>


      <Heading fontSize={'sm'}>
                C. Tersedia kupon setiap transaksi dan bisa ditukarkan dengan makanan setiap kunjungan akhir bulan
      </Heading>

      <Stack w='full' pt={5}>
        <DynamicButton action='custom' title='lanjutkan' color='blue' variant='solid' onClick={handleNext}/>
      </Stack>


    </Stack>
  )
}

export default TermConditionPage