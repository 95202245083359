import { Divider, HStack, Heading,  Stack, Text } from '@chakra-ui/react'
import React from 'react'
import DynamicButton from '../../Components/Buttons/DynamicButton'

function MissionsPage() {



  const height = window.innerHeight

  const missions = [
    {
      title: 'Setiap pembelian 4 Mie Gacoan level apapun',
      description: 'Dengan menyelesaikan misi ini kamu akan mendapat 1 voucher mie gratis',
      value: '2',
      target: '4'
    },
    {
      title: 'Melakukan orderan min 3x dalam seminggu',
      description: 'Dengan menyelesaikan misi ini kamu akan mendapat 1 voucher mie + minuman 1 paket gratis',
      value: '2',
      target: '2'
    }
  ]

  return (
    <Stack p={2} minH={height}>
      <Stack alignItems={'flex-end'}>
        <Heading size={'md'}>My Missions</Heading>
      </Stack>
      <Divider />
      <Stack>
        {missions?.length > 0 ? (
          missions?.map((x, index) =>
            <Stack bgColor={'white'} key={index} spacing={1} borderRadius='xl' p={2} shadow={'md'}>



              <HStack spacing={3} h={'100%'} alignItems={'flex-start'}>

                <Stack spacing={2} w={'full'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                  <Stack>
                    <Text numberOfLines={2} fontWeight={500} fontSize='sm'>{(x.title)}</Text>
                  </Stack>
                  <Stack>
                    <Text numberOfLines={2} fontSize='xs'>{(x.description)}</Text>
                  </Stack>
                  <Stack w={'full'} alignItems={'flex-end'} justifyContent={'flex-end'}>
                    <Text color={'gray.700'} fontSize='xs' fontWeight={'bold'}>Mission : ( {x?.value} / {x?.target} )</Text>
                  </Stack>
                  {x?.value === x?.target && (
                    <Stack w={'full'} alignItems={'flex-end'} justifyContent={'flex-end'}>
                      <DynamicButton action={'custom'} color={'green'} variant={'solid'} size={'xs'} title={'Claim'} />
                    </Stack>
                  )}


                </Stack>


              </HStack>


            </Stack>
          )
        ) : (
          <Stack h={height / 2} alignItems={'center'} justifyContent='center' >
            <Text color={'gray.500'} fontWeight='bold'>Tidak ada data voucher</Text>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export default MissionsPage