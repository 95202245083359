import { create } from 'zustand';


export const UseAuthStore = create((set, ) => ({
  isLoggedIn: false,
  user : {},
  tokens : {},
  isLoading: false,
  condition: false,
  

  setTokens: (data) => {
    set({tokens: data});
  },

  setUser: (data) => {
    set({user: data});
  },

  setIsLoggedIn: (data) => {
    set({isLoggedIn : data});
  },

  setIsLoading: (data) => {
    set({isLoading : data});
  },

  setCondition: (data) => {
    set({condition: data});
  }
  
}));
