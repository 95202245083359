import { Button, HStack, Image, Spacer, Stack, useToast } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import logobelanja from '../../Assets/images/logomie.png'
import { UseAuthStore } from '../../Hooks/Zustand/store';


function AppHeader() {


  const toast = useToast()



  const globalState = UseAuthStore();


  const handleLogOut = () => {
    globalState.setIsLoggedIn(false)
    toast({
      title: 'Gen Gacoan',
      description: 'Berhasil logout',
      position:'top',
      status: 'success',
      isClosable: true,
      duration: 9000
    });
  }

  return (
    <HStack bgColor='white'  px={5} py={2} width='full' shadow={''} >
      <Stack alignItems={'center'} justifyContent='center' >
        <Image height='50px' src={logobelanja}  />
      </Stack>
      <Spacer />
      {globalState?.isLoggedIn ? (
        <HStack>
          <Button size={'sm'} onClick={() => handleLogOut()} colorScheme='blue' variant='solid'>
							Logout
          </Button>
        </HStack>
      ) : (
        <HStack>
          <Link to='/login'>
            <Button  size={'sm'} colorScheme='blue' variant='outline'>
								Login
            </Button>
          </Link>
          <Link to='/signup'>
            <Button size={'sm'} colorScheme='blue' variant='solid'>
								Bergabung
            </Button>
          </Link>
        </HStack>
      )}
    </HStack>
  )
}

export default AppHeader