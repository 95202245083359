/* eslint-disable react/prop-types */
import React from 'react'
import { Image, Stack, Text } from '@chakra-ui/react';
import { formatFrice } from '../../Utils/Helper';

function CardProductVoucherCol({menu, id}) {
  return (
    <Stack
      p={2}
      key={id}
      spacing={2}
      borderRadius="lg"
      borderWidth={0.5}
      shadow="lg"
      borderColor="#gray.300"
    >
      <Image
        src={menu?.image || ''}
        alt={menu?.name || ''}
        shadow={'md'}
        borderRadius="lg"
        style={{ width: '100%', height: '150px', objectFit: 'cover' }}
      />

      <Stack spacing={1}>
        <Text
          fontSize="md"
          textTransform="capitalize"
          fontWeight={'bold'}
          noOfLines={1}
        >
          {menu?.name || 'Menus'}
        </Text>

        <Text fontSize="xs" textTransform="capitalize" noOfLines={1}>
          {menu?.description || 'Desc'}
        </Text>


        <Text fontSize={'sm'}>
                Rp. {formatFrice(parseFloat(menu?.price || 0))}
        </Text>
      </Stack>
    </Stack>
  )
}

export default CardProductVoucherCol