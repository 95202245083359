import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import HomeRouter from './HomeRouter';
import ProfileRouter from './ProfileRouter';
import VoucherRouter from './VoucherRouter';
import OrdersRouter from './OrdersRouter';
import MissionsRouter from './MissionsRouter';
import { useNavigate } from 'react-router-dom'
import { UseAuthStore } from '../Hooks/Zustand/store';



function MainRouter() {

  const navigate = useNavigate()
  const globalState = UseAuthStore();


  const allRouter = [
    ...HomeRouter,
    ...ProfileRouter,
    ...VoucherRouter,
    ...OrdersRouter,
    ...MissionsRouter
  ];


  useEffect(() => {

    if(globalState?.condition === false){
      navigate('/termcondition')
    }

    return () => {};
  }, []);


  return (
    <Routes>
      {allRouter.map((item, index) => {
        return <Route key={index} path={item.path} element={item.element} />;
      })}
    </Routes>
  );
}

export default MainRouter