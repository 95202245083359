/* eslint-disable react/prop-types */
import React from 'react';
import { Button, HStack, Text } from '@chakra-ui/react';
import { FaPlus, FaEdit, FaTrash, FaSearch } from 'react-icons/fa';

const DynamicButton = ({ action, title, onClick, disabled, size, variant, icon, color, isLoading }) => {
  let Icon;
  let colorScheme;

  switch (action) {
  case 'create':
    Icon = FaPlus
    colorScheme = 'green'
    break;
  case 'update':
    Icon = FaEdit
    colorScheme = 'yellow'
    break;
  case 'delete':
    Icon = FaTrash
    colorScheme = 'red'
    break;
  case 'read': //ex: read, or detail, or search
    Icon = FaSearch
    colorScheme = 'blue'
    break;

  case 'custom': //ex: read, or detail, or search
    Icon = icon;
    colorScheme = color;
    break;
  default:
    Icon = null;
  }

  return (
    <Button shadow="base" isLoading={isLoading} size={size && size} onClick={onClick} variant={variant ? variant : 'outline'} colorScheme={colorScheme} isDisabled={disabled ? disabled : false}>
      <HStack alignItems="center">
        {Icon && <Icon size={13} />}
        {title && <Text fontSize={'sm'}>{title}</Text>}
      </HStack>
    </Button>
  );
};

export default DynamicButton;
