/* eslint-disable react/prop-types */
import { Card, CardBody, Flex, Heading, Image, Stack, Text, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, DrawerCloseButton, DrawerFooter, HStack, Button,  Spacer  } from '@chakra-ui/react';
import React, {useState} from 'react'
import { formatFrice } from '../../Utils/Helper';
import MyVoucherDetail from '../../Pages/Voucher/MyVoucher/MyVoucherDetail';


function CardMyVoucherRow({item, id}) {

  const [drawerDetail, setDrawerDetail] = useState(false)
  const [voucherActive, setVoucherActive] = useState('')

  const handleOpenDrawer = () => {
    setDrawerDetail(true)
    setVoucherActive(item)
  }

  const handleCloseDrawer = () => {
    setDrawerDetail(false)
    setVoucherActive('')
  }


    
  return (
    <Stack>
      <Card direction="row" overflow="hidden" key={id} onClick={() => handleOpenDrawer(item)}>
        <Flex
          direction="column"
          w="30%"
          justify="center"
          align="center"
          p={2}
        >
          <Image
            shadow={'md'}
            borderRadius={'md'}
            src={item?.voucher?.image || ''}
            alt={item?.voucher?.name || ''}
            style={{ width: '100%', height: '120px', objectFit: 'cover' }}
          />
        </Flex>
        <CardBody w="70%">
          <Stack spacing={1}>
            <Heading size="sm" noOfLines={1}>{item?.voucher?.name || ''}</Heading>
            <Text fontSize="xs">{item?.voucher?.description || ''}</Text>

            <Text fontSize="sm">
                            Rp {formatFrice(parseInt(item?.voucher?.price || 0))}
            </Text>
            <Stack alignItems={'flex-end'}>
              <Stack>
              </Stack>
            </Stack>
          </Stack>
        </CardBody>
      </Card>

   
      <Drawer placement={'bottom'} onClose={handleCloseDrawer} size={'xl'} isOpen={drawerDetail}>
        <DrawerOverlay />

        <DrawerContent >
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='0.5px'>
            <Text fontSize={'md'}>Voucher {voucherActive?.voucher?.name}</Text>
          </DrawerHeader>
          <DrawerBody>
            <MyVoucherDetail voucherActive={voucherActive}/>
          </DrawerBody>

          <DrawerFooter outline={0.5}>
            <Stack w={'full'}>
              <Stack spacing={3}>
                <HStack>
                  <Text textTransform={'uppercase'}>Subtotal</Text>
                  <Spacer />
                  <Text fontWeight={500}>IDR {formatFrice(voucherActive?.voucher?.price || 0)}</Text>
                </HStack>
                <Text textAlign='center' fontSize={'xs'} color='gray.500'>Scan this barcode to cashier.
                </Text>
                <Button bgColor={'black'} color='white' onClick={() => handleCloseDrawer()}>Finish</Button>
              </Stack>
            </Stack>
          </DrawerFooter>
        </DrawerContent>

      </Drawer>


    </Stack>
  )
}

export default CardMyVoucherRow